import { PropsWithChildren } from 'react'
import MainNavigation from '@/components/nav/MainNavigation'
import Footer from '@/components/custom/footer'
import BreakpointIndicator from '@/components/experiments/breakpoint-indicator'
import BackToTop from '@/components/custom/back-to-top'
import { Toaster } from '@/components/ui/toaster'
import CardWithMarqueeBackground from '@/components/experiments/marquee-demo'

export default function Layout({ children }: PropsWithChildren) {
  return (
    <>
      <MainNavigation />

      <main className="relative mx-auto mt-4 flex max-w-7xl flex-col gap-10 px-4 md:mt-8 md:gap-16 md:px-8 lg:mt-12 lg:px-[100px]">
        {children}
        <Toaster />
      </main>
      <CardWithMarqueeBackground />

      <Footer />
      <BreakpointIndicator />
      {/* <BackToTop /> */}
    </>
  )
}
