'use client'

import { motion } from 'framer-motion'
import { cn } from '@/lib/utils'
import { Icon } from '@/components/icons/index'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'

interface NewsCardProps {
  className?: string
  excerpt: string
  imageUrl: string
  publishedAt: string
  title: string
  onClick: () => void
}

export default function NewsCard({
  className,
  excerpt,
  imageUrl,
  publishedAt,
  title,
  onClick,
}: NewsCardProps) {
  return (
    <Card className="group overflow-hidden !rounded-[20px] border-none bg-card shadow-md transition-shadow duration-300 hover:shadow-lg">
      <motion.div
        className={cn(
          'flex h-[380px] w-full flex-col gap-[22px] sm:w-[260px] md:w-[350px]',
          className
        )}
        whileHover="hover"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <CardHeader className="relative h-[200px] overflow-hidden">
          <motion.div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${imageUrl})` }}
            variants={{
              initial: { scale: 1 },
              hover: { scale: 1.2 },
            }}
            transition={{
              duration: 0.3,
            }}
          />
        </CardHeader>
        <CardContent className="flex flex-col items-center justify-start gap-4 py-0">
          <p className="self-start text-xs text-body">{publishedAt}</p>
          <CardTitle className="line-clamp-1 self-start text-xl font-semibold leading-tight tracking-tighter text-foreground">
            {title}
          </CardTitle>
          <p className="line-clamp-3 text-sm font-medium text-body">{excerpt}</p>
        </CardContent>
      </motion.div>
      <CardFooter className="flex justify-end">
        <button
          onClick={onClick}
          className="flex items-center justify-center space-x-0.5 text-foreground outline-none group-hover:text-primary"
        >
          <span className="text-xs font-medium">More Details</span>
          <Icon name="arrow-right" className="size-2" />
        </button>
      </CardFooter>
    </Card>
  )
}
