import { PropsWithChildren, useEffect } from 'react'

import { motion } from 'framer-motion'
import RCarousel from '@/components/custom/carousel'
import EasyAccess from '@/components/custom/easy-access'
import { SimpleSection } from '@/components/custom/simple-section'
import { ImageOnlySection } from '@/components/custom/image-only-section'
import BundleCard from '@/components/custom/bundle-card'
import { SplitSection } from '@/components/custom/split-section'
import NewsCard from '@/components/custom/news-card'
import SupportSection from '@/components/custom/support-section'
import MyRoshanCallToAction from '@/components/custom/myroshan-call-to-action'
import ExpandingCardsExample from '@/components/experiments/exp-accordion-card'
import TelecomPlansGrid from '@/components/experiments/dynamic-island'
import TelecomPlansGrid1 from '@/components/experiments/dynamic-island-first-iteration'
import NewsGrid from '@/components/sections/news-section'
import NewsSection from '@/components/sections/news-section'
import HoverCardFrame from '@/components/experiments/hover-frame-effect'
import { usePage } from '@inertiajs/react'
import { PageProps } from '@/types'

const IMAGES = ['/images/home-ph.jpeg', '/images/home-2-ph.png', '/images/home-ph.jpeg']

const data = [
  {
    id: 1,
    title: 'The Future of AI in Healthcare: Revolutionizing Diagnosis and Treatment',
    excerpt:
      'Artificial Intelligence is revolutionizing the healthcare industry, from diagnosis to treatment planning.',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. '.repeat(
        3
      ),
    imageUrl: '/images/home-2-ph.png',
    publishedDate: '2023-05-15',
    author: 'Dr. Jane Smith',
  },
  {
    id: 2,
    title: 'Sustainable Energy Solutions: Paving the Way for a Greener Future',
    excerpt: 'New breakthroughs in renewable energy are paving the way for a greener future.',
    content:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. '.repeat(
        3
      ),
    imageUrl: '/images/news-card-header.png',
    publishedDate: '2023-05-14',
    author: 'Prof. John Doe',
  },
  {
    id: 3,
    title: 'The Rise of Remote Work: Adapting to the New Normal',
    excerpt: 'Companies are adapting to the new normal with innovative remote work policies.',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. '.repeat(
        3
      ),
    imageUrl: '/images/news-card-header.png',
    publishedDate: '2023-05-13',
    author: 'Sarah Johnson',
  },
]

export default function Home({ children }: PropsWithChildren) {
  const { carousel } = usePage().props
  useEffect(() => {
    console.log('url', carousel)
  }, [carousel])
  return (
    <>
      <motion.div
        className="absolute top-[20px] h-[200px] w-[200px] rounded-full bg-gradient-to-t from-[#f00028] to-[#0046b4] blur-[140px] [--left-end:calc(100%-360px)] [--left-start:20px] md:top-[25px] md:h-[288px] md:w-[320px] md:[--left-end:calc(100%-365px)] md:[--left-start:45px] lg:top-[30px] lg:h-[360px] lg:w-[400px] lg:[--left-end:calc(100%-500px)] lg:[--left-start:140px]"
        animate={{
          left: ['var(--left-start)', 'var(--left-end)'],
          rotate: [-90, 0, 90, 0, -90],
          // scale: [1.2, 1, 0.8, 1, 1.2],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'easeInOut',
        }}
      />

      <RCarousel
        // images={carousel.url}
        images={IMAGES}
        className="w-full"
        imageClass="object-fit w-full aspect-[4/2] h-auto max-h-[450px] rounded-2xl md:rounded-[28px] lg:rounded-[40px]"
      />

      <EasyAccess></EasyAccess>

      {/* <SimpleSection
        title="NEW OFFERS"
        subtitle="Unlock Exclusive Deals! Explore our latest offers now"
      >
        <div className="grid grid-cols-1 gap-[27px] sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <BundleCard
            title="5GB"
            imageUrl="/images/bundle-header-image.png"
            price="699 Afn"
            validity="30 Days"
            ussd="Dial *555#"
            activation="Send R50 to 555"
            deactivation="Send R50 to 555"
          />

          <BundleCard
            title="5GB"
            imageUrl="/images/bundle-header-image.png"
            price="699 Afn"
            validity="30 Days"
            ussd="Dial *555#"
            activation="Send R50 to 555"
            deactivation="Send R50 to 555"
          />

          <BundleCard
            title="5GB"
            imageUrl="/images/bundle-header-image.png"
            price="699 Afn"
            validity="30 Days"
            ussd="Dial *555#"
            activation="Send R50 to 555"
            deactivation="Send R50 to 555"
          />

          <BundleCard
            title="5GB"
            imageUrl="/images/bundle-header-image.png"
            price="699 Afn"
            validity="30 Days"
            ussd="Dial *555#"
            activation="Send R50 to 555"
            deactivation="Send R50 to 555"
          />
        </div>
      </SimpleSection> */}

      <TelecomPlansGrid1 />
      {/* <TelecomPlansGrid /> */}

      <ImageOnlySection
        titles={['Credit Loan', 'Data Loan', 'Credit Share', 'Data Share', 'Bundle Share']}
        imgUrl="/images/home-special-section.png"
        className="mx-auto w-full"
      ></ImageOnlySection>

      <SupportSection />

      <SplitSection />

      {/* <SimpleSection
        title="ROSHAN NEWS"
        subtitle="Stay Updated with Roshan’s Latest Announcements!"
      >
        <div className="grid-cols-max grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
          {[1, 2, 3].map((item, index, array) => {
            const isLastItem = index === array.length - 1
            return (
              <NewsCard
                key={`news-${item}`}
                title={`Roshan News-${item}`}
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard."
                publishedAt="12/12/2021"
                imageUrl="/images/news-card-header.png"
                className={isLastItem ? 'md:col-span-2 lg:col-span-1' : 'col-span-1'}
              />
            )
          })}
        </div>
      </SimpleSection> */}
      <NewsSection
        title="ROSHAN NEWS"
        subtitle="Stay Updated with Roshan’s Latest Announcements!"
        data={data}
      />
      {/* <MyRoshanCallToAction /> */}
    </>
  )
}
