import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Importing JSON translations
import en from '../../lang/en.json'
import fa from '../../lang/fa.json'

i18n
  .use(LanguageDetector) // Detect language from localStorage or browser
  .use(initReactI18next) // Connect i18n to React
  .init({
    resources: {
      en: { translation: en },
      fa: { translation: fa },
    },
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  })

export default i18n
