'use client'

import { useState, useEffect } from 'react'

export default function BreakpointIndicator() {
  const [breakpoint, setBreakpoint] = useState('')

  useEffect(() => {
    const updateBreakpoint = () => {
      const width = window.innerWidth
      if (width >= 1536) {
        setBreakpoint('2xl')
      } else if (width >= 1280) {
        setBreakpoint('xl')
      } else if (width >= 1024) {
        setBreakpoint('lg')
      } else if (width >= 768) {
        setBreakpoint('md')
      } else if (width >= 640) {
        setBreakpoint('sm')
      } else {
        setBreakpoint('xs')
      }
    }

    // Initial call
    updateBreakpoint()

    // Add event listener
    window.addEventListener('resize', updateBreakpoint)

    // Cleanup
    return () => window.removeEventListener('resize', updateBreakpoint)
  }, [])

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="rounded-full bg-blue-500 px-3 py-2 text-sm font-bold text-white shadow-lg">
        {breakpoint}
      </div>
      <div className="sr-only">Current breakpoint: {breakpoint}</div>
    </div>
  )
}
