'use client'

import React, { useState, useCallback, memo, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const cn = (...classes: (string | boolean | undefined)[]) => classes.filter(Boolean).join(' ')

interface CardType {
  id: string
  title: string
  description: string
  image: string
}

interface ExpandingCardsProps {
  className?: string
  children: React.ReactNode
}

const ExpandingCards = memo(
  React.forwardRef<HTMLDivElement, ExpandingCardsProps>(({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex h-[400px] flex-nowrap justify-start overflow-hidden', className)}
      {...props}
    />
  ))
)
ExpandingCards.displayName = 'ExpandingCards'

interface ExpandingCardItemProps {
  className?: string
  image: string
  isActive: boolean
  onClick: () => void
  children: React.ReactNode
}

const ExpandingCardItem = memo(
  React.forwardRef<HTMLDivElement, ExpandingCardItemProps>(
    ({ className, image, isActive, onClick, children, ...props }, ref) => (
      <motion.div
        ref={ref}
        className={cn(
          'relative m-0 mx-2.5 flex cursor-pointer overflow-hidden rounded-3xl bg-cover shadow-lg',
          isActive ? 'w-[600px]' : 'w-20',
          isActive ? 'items-end' : 'flex-col items-center justify-between',
          className
        )}
        style={{ backgroundImage: `url(${image})` }}
        layout
        onClick={onClick}
        initial={false}
        animate={isActive ? { opacity: 1, width: '600px' } : { opacity: 1, width: '80px' }}
        transition={{
          opacity: { duration: 0.3, ease: 'easeInOut' },
          width: { duration: 0.4, ease: 'easeInOut' },
        }}
        {...props}
      >
        <motion.div
          className="absolute inset-0 bg-black bg-opacity-50"
          initial={false}
          animate={{ opacity: isActive ? 0 : 0.7 }}
          transition={{ duration: 0.3 }}
        />
        {children}
      </motion.div>
    )
  )
)
ExpandingCardItem.displayName = 'ExpandingCardItem'

interface ExpandingCardContentProps {
  className?: string
  isActive: boolean
  children: React.ReactNode
}

const ExpandingCardContent = memo(
  React.forwardRef<HTMLDivElement, ExpandingCardContentProps>(
    ({ className, isActive, children, ...props }, ref) => (
      <motion.div
        ref={ref}
        className={cn(
          'relative flex h-full w-full text-white',
          isActive ? 'items-end p-8' : 'flex-col items-center justify-end space-y-1 py-4',
          className
        )}
        initial={false}
        animate={isActive ? 'active' : 'inactive'}
        variants={{
          active: { transition: { delay: 0.3, duration: 0.3 } },
          inactive: { transition: { duration: 0.3 } },
        }}
        {...props}
      >
        {children}
      </motion.div>
    )
  )
)
ExpandingCardContent.displayName = 'ExpandingCardContent'

interface ExpandingCardIconProps {
  className?: string
  isActive: boolean
  children: React.ReactNode
}

const ExpandingCardIcon = memo(
  React.forwardRef<HTMLDivElement, ExpandingCardIconProps>(
    ({ className, isActive, ...props }, ref) => (
      <motion.div
        ref={ref}
        className={cn(
          'flex size-10 items-center justify-center rounded-full bg-[#223] text-sm font-bold text-white',
          isActive ? 'mr-4' : 'mb-4',
          className
        )}
        variants={{
          active: { y: 0, scale: 1, opacity: 0 },
          inactive: { y: 0, scale: 1, opacity: 1 },
        }}
        transition={{ duration: 0.3, ease: 'linear' }}
        {...props}
      />
    )
  )
)
ExpandingCardIcon.displayName = 'ExpandingCardIcon'

interface ExpandingCardTitleProps {
  className?: string
  isActive: boolean
  children: React.ReactNode
}

const ExpandingCardTitle = memo(
  React.forwardRef<HTMLDivElement, ExpandingCardTitleProps>(
    ({ className, isActive, children, ...props }, ref) => (
      <motion.div
        ref={ref}
        className={cn('text-xl font-bold', isActive ? 'mb-2' : 'whitespace-nowrap', className)}
        {...props}
      >
        <AnimatePresence mode="wait">
          {isActive ? (
            <motion.h4
              key="active"
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 40 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              {children}
            </motion.h4>
          ) : (
            <motion.h4
              key="inactive"
              initial={{
                opacity: 0,
                x: -20,
                transform: 'rotate(180deg)',
                writingMode: 'vertical-lr',
              }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
              style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
            >
              {children}
            </motion.h4>
          )}
        </AnimatePresence>
      </motion.div>
    )
  )
)
ExpandingCardTitle.displayName = 'ExpandingCardTitle'

interface ExpandingCardDescriptionProps {
  className?: string
  children: React.ReactNode
}

const ExpandingCardDescription = memo(
  React.forwardRef<HTMLParagraphElement, ExpandingCardDescriptionProps>(
    ({ className, ...props }, ref) => (
      <motion.p
        ref={ref}
        className={cn('text-sm text-gray-200', className)}
        variants={{
          active: { opacity: 1, y: 0 },
          inactive: { opacity: 0, y: 0 },
        }}
        transition={{ duration: 0.4, delay: 0.35 }}
        {...props}
      />
    )
  )
)
ExpandingCardDescription.displayName = 'ExpandingCardDescription'

interface CardProps {
  card: CardType
  index: number
  isActive: boolean
  onClick: () => void
}

const Card: React.FC<CardProps> = memo(({ card, index, isActive, onClick }) => (
  <ExpandingCardItem image={card.image} isActive={isActive} onClick={onClick}>
    <ExpandingCardContent isActive={isActive}>
      {isActive ? (
        <div className="flex flex-row items-center">
          <ExpandingCardIcon isActive={isActive}>{index + 1}</ExpandingCardIcon>
          <div className="flex flex-col">
            <ExpandingCardTitle isActive={isActive}>{card.title}</ExpandingCardTitle>
            <ExpandingCardDescription>{card.description}</ExpandingCardDescription>
          </div>
        </div>
      ) : (
        <>
          <ExpandingCardTitle isActive={isActive}>{card.title}</ExpandingCardTitle>
          <ExpandingCardIcon isActive={isActive}>{index + 1}</ExpandingCardIcon>
        </>
      )}
    </ExpandingCardContent>
  </ExpandingCardItem>
))
Card.displayName = 'Card'

export default function ExpandingCardsPreview() {
  const [activeCard, setActiveCard] = useState('c1')
  const [isUserInteracting, setIsUserInteracting] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const cards = [
    {
      id: 'c1',
      title: 'Winter',
      description: 'Winter has so much to offer - creative activities',
      image: '/images/e-sim.png',
    },
    {
      id: 'c2',
      title: 'Digital Technology',
      description: 'Gets better every day - stay tuned',
      image: '/images/home-ph.jpeg',
    },
    {
      id: 'c3',
      title: 'Globalization',
      description: 'Help people all over the world',
      image: '/images/home-2-ph.png',
    },
    {
      id: 'c4',
      title: 'New technologies',
      description: 'Space engineering becomes more and more advanced',
      image: '/images/news-card-header.png',
    },
  ]

  const handleCardClick = useCallback((id: string) => {
    setActiveCard(id)
    setIsUserInteracting(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setIsUserInteracting(false)
    }, 5000) // Resume autoplay after 5 seconds of inactivity
  }, [])

  const handleMouseEnter = useCallback(() => {
    setIsUserInteracting(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const handleMouseLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setIsUserInteracting(false)
    }, 2000) // Resume autoplay after 2 seconds of mouse leave
  }, [])

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      if (!isUserInteracting) {
        const currentIndex = cards.findIndex((card) => card.id === activeCard)
        const nextIndex = (currentIndex + 1) % cards.length
        setActiveCard(cards[nextIndex].id)
      }
    }, 3000) // Change card every 3 seconds

    return () => {
      clearInterval(autoplayInterval)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [activeCard, cards, isUserInteracting])

  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-gray-100 p-4"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ExpandingCards>
        {cards.map((card, index) => (
          <Card
            key={card.id}
            card={card}
            index={index}
            isActive={activeCard === card.id}
            onClick={() => handleCardClick(card.id)}
          />
        ))}
      </ExpandingCards>
    </div>
  )
}
