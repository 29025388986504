'use client'

import React from 'react'
import WordFadeIn from '@/components/ui/word-fade-in'

export interface SimpleSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  subtitle?: string
}

export function SimpleSection({ title, subtitle, children, className }: SimpleSectionProps) {
  return (
    <section className="flex flex-col items-center justify-center gap-10">
      <div className="text-center">
        {title && (
          <WordFadeIn
            className="text-2xl font-semibold leading-snug tracking-tight text-foreground lg:text-[32px]"
            words={title.trim()}
            useWhileInView={true}
            viewportOnce={true}
            viewportAmount={1}
            delay={0.3}
          />
        )}
        {subtitle && (
          <WordFadeIn
            className="px-10 text-sm font-normal leading-tight tracking-tight text-body lg:text-xl"
            words={subtitle.trim()}
            useWhileInView={true}
            viewportOnce={true}
            viewportAmount={1}
            delay={0.05}
          />
        )}
      </div>
      {children}
    </section>
  )
}
