'use client'

import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import { Facebook, Twitter, Linkedin } from 'lucide-react'
import { SimpleSection } from '@/components/custom/simple-section'
import NewsCard from '../custom/news-card'

export interface NewsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  subtitle?: string
  data?: {
    id: number
    title: string
    excerpt: string
    content: string
    imageUrl: string
    publishedDate: string
    author?: string | null
  }[]
}

export default function NewsSection({ title, subtitle, data = [] }: NewsSectionProps) {
  const [selectedCard, setSelectedCard] = useState<(typeof data)[0] | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const openCardDetail = (selectedCard: (typeof data)[0]) => {
    setSelectedCard(selectedCard)
    setIsOpen(true)
  }

  //   const handleShare = (platform) => {
  //     if (!selectedCard) return;

  //     let shareUrl = '';
  //     const articleUrl = `https://yourdomain.com/post/${selectedCard.id}`; // Replace with your actual post URL
  //     const text = encodeURIComponent(`Check out this post: ${selectedCard.title}`);

  //     switch (platform) {
  //       case 'facebook':
  //         shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(articleUrl)}`;
  //         break;
  //       case 'twitter':
  //         shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(articleUrl)}&text=${text}`;
  //         break;
  //       case 'linkedin':
  //         shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(articleUrl)}&title=${text}`;
  //         break;
  //       default:
  //         console.log('Invalid platform');
  //         return;
  //     }

  //     window.open(shareUrl, '_blank');
  //   }

  return (
    <SimpleSection title={title} subtitle={subtitle}>
      <div className="grid auto-cols-max grid-cols-1 gap-6 px-12 sm:grid-cols-2 sm:px-0 md:gap-10 lg:grid-cols-3">
        {data.map((post) => (
          <div key={post.id}>
            <NewsCard
              title={post.title}
              excerpt={post.excerpt}
              imageUrl={post.imageUrl}
              publishedAt={post.publishedDate}
              onClick={() => openCardDetail(post)}
            />
          </div>
        ))}
      </div>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="flex h-[90vh] max-w-4xl flex-col lg:rounded-[22px]">
          {selectedCard && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ duration: 0.3 }}
              className="flex h-full flex-col gap-2"
            >
              <DialogHeader className="space-y-1">
                <div className="flex items-center justify-between">
                  <DialogTitle className="text-2xl">{selectedCard.title}</DialogTitle>
                  <div className="flex space-x-2">
                    <Button variant="ghost" size="icon" onClick={(e) => e.preventDefault()}>
                      <Facebook className="h-4 w-4" />
                      <span className="sr-only">Share on Facebook</span>
                    </Button>
                    <Button variant="ghost" size="icon" onClick={(e) => e.preventDefault()}>
                      <Twitter className="h-4 w-4" />
                      <span className="sr-only">Share on Twitter</span>
                    </Button>
                    <Button variant="ghost" size="icon" onClick={(e) => e.preventDefault()}>
                      <Linkedin className="h-4 w-4" />
                      <span className="sr-only">Share on LinkedIn</span>
                    </Button>
                  </div>
                </div>
              </DialogHeader>
              <div className="flex flex-grow flex-col gap-y-5 overflow-y-scroll px-4">
                <img
                  src={selectedCard.imageUrl}
                  alt={selectedCard.title}
                  className="object-fit my-4 aspect-[4/2] h-auto max-h-[450px] w-full rounded-2xl md:rounded-[28px] lg:rounded-[40px]"
                />
                <p className="leading-relaxed text-gray-700">{selectedCard.content}</p>
              </div>
            </motion.div>
          )}
        </DialogContent>
      </Dialog>
    </SimpleSection>
  )
}
