import './bootstrap'
import '../css/app.css'
import Layout from './Layouts/Layout'
import { createRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ReactNode, StrictMode } from 'react'
import '@/lib/i18n'
const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.tsx', { eager: true })
    const page: any = pages[`./Pages/${name}.tsx`]
    page.default.layout = page.default.layout || ((page: ReactNode) => <Layout children={page} />)
    return page
  },
  setup({ el, App, props }) {
    const root = createRoot(el)

    root.render(
      <StrictMode>
        <App {...props} />
      </StrictMode>
    )
  },
  progress: {
    color: '#4B5563',
  },
})
