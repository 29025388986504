'use client'

import { useState, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { Icon, IconName } from '@/components/icons/index'
import GradualSpacing from '@/components/ui/gradual-spacing'

type PartialDescriptionList = Partial<Record<IconName, string>>

const descriptionList: PartialDescriptionList = {
  'battery-charging': 'Easy Activation',
  lock: 'Ironclad Security',
  'setting-switch': 'Instant Network Switching',
  'check-circle-slim': 'Low Consumption on Battery',
}

export function SplitSection() {
  const [isHovered, setIsHovered] = useState(false)
  const controls = useAnimation()

  useEffect(() => {
    const animateCards = async () => {
      if (isHovered) {
        await controls.start((i) => ({
          translateX: `-${50 + i * 5}%`,
          translateY: '-50%',
          rotate: 0,
          skewX: 0,
          skewY: 0,
          scale: 1.5,
          y: 0,
          x: 0,
          zIndex: 3 - i,
          transition: { duration: 0.3 },
        }))
      } else {
        await controls.start((i) => ({
          translateX: '-50%',
          translateY: '-50%',
          rotate: -39,
          skewX: 15,
          skewY: 10,
          scale: 1,
          y: 0,
          x: 0,
          zIndex: 'auto',
          transition: { duration: 0.3 },
        }))
        controls.start((i) => ({
          scale: [1, 1.05, 1],
          y: [0, -5, 0],
          x: [0, -5, 0],
          transition: {
            duration: 5 + i,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          },
        }))
      }
    }
    animateCards()
  }, [isHovered, controls])

  return (
    <section className="grid grid-cols-1 gap-8 px-12 pb-12 lg:grid-cols-3">
      <div className="col-span-1 flex flex-col items-center justify-center gap-10">
        <div className="lg:px-auto px-3 text-center lg:text-left">
          <GradualSpacing
            className="text-lg font-semibold tracking-tighter text-body"
            text="Go digital with"
            useWhileInView={true}
            viewportOnce={true}
          />
          <GradualSpacing
            className="block whitespace-nowrap text-5xl font-bold tracking-tighter text-foreground"
            text="Roshan eSIM"
            useWhileInView={true}
            viewportOnce={true}
          />
        </div>
        <div className="grid grid-cols-2 gap-8">
          {(Object.entries(descriptionList) as [IconName, string][]).map(([key, value], index) => (
            <motion.div
              key={key}
              className="col-span-1 flex flex-col items-center justify-center gap-3 rounded-xl p-2"
              initial={{
                opacity: 0,
                x: index % 2 === 0 ? '-50%' : '50%',
                y: index < 2 ? '-50%' : '50%',
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                y: 0,
              }}
              transition={{
                duration: 0.5,
                delay: index * 0.1,
                ease: 'easeOut',
              }}
              viewport={{ once: true }}
            >
              <Icon name={key} className="h-8 w-8 p-0.5 text-primary" />
              <p className="w-full text-center text-sm font-medium leading-snug tracking-tight text-muted-foreground">
                {value}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="relative col-span-1 lg:col-span-2">
        <div className="absolute right-[95px] top-[36px] h-[377px] w-96 rounded-full bg-gradient-to-t from-[#f00028] to-[#0046b4] blur-[140px]"></div>
        <motion.div
          className="relative h-[400px] w-full lg:h-[540px]"
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          initial={{
            opacity: 0,
            x: 20,
            y: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            y: 0,
          }}
          transition={{
            duration: 0.5,
            delay: 0.1,
            ease: 'easeOut',
          }}
          viewport={{ once: true }}
        >
          <div className="absolute inset-0 flex h-full w-full items-center justify-center">
            <div className="relative h-[350px] w-[280px] lg:h-[480px] lg:w-[420px]">
              {[
                { top: '65%', zIndex: 3 },
                { top: '50%', zIndex: 2 },
                { top: '35%', zIndex: 1 },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="absolute left-1/2 h-[120px] w-[200px] origin-center lg:h-[180px] lg:w-[300px]"
                  style={{ top: item.top }}
                  initial={{
                    translateX: '-50%',
                    translateY: '-50%',
                    rotate: -39,
                    skewX: 15,
                    skewY: 10,
                    scale: 1,
                  }}
                  animate={controls}
                  custom={index}
                >
                  <img
                    src="/images/e-sim.png"
                    alt="eSIM card"
                    className="h-full w-full rounded-xl shadow-[0_100px_100px_rgba(0,0,0,0.25)] lg:rounded-[22px]"
                    loading="lazy"
                    fetchPriority="high"
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}
