'use client'

import { AnimatePresence, motion, Variants } from 'framer-motion'
import { cn } from '@/lib/utils'

interface GradualSpacingProps {
  text: string
  duration?: number
  delayMultiple?: number
  framerProps?: Variants
  className?: string
  useWhileInView?: boolean
  viewportOnce?: boolean
  viewportAmount?: number | 'some' | 'all'
}

export default function GradualSpacing({
  text,
  duration = 0.5,
  delayMultiple = 0.04,
  framerProps = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  },
  className,
  useWhileInView = false,
  viewportOnce = true,
  viewportAmount = 0.5,
}: GradualSpacingProps) {
  const animationProps = useWhileInView
    ? {
        initial: 'hidden',
        whileInView: 'visible',
        viewport: { once: viewportOnce, amount: viewportAmount },
      }
    : {
        initial: 'hidden',
        animate: 'visible',
      }

  return (
    <div className="flex justify-start">
      <AnimatePresence>
        {text.split('').map((char, i) => (
          <motion.h1
            key={i}
            exit="hidden"
            variants={framerProps}
            transition={{ duration, delay: i * delayMultiple }}
            className={cn('drop-shadow-sm', className)}
            {...animationProps}
          >
            {char === ' ' ? <span>&nbsp;</span> : char}
          </motion.h1>
        ))}
      </AnimatePresence>
    </div>
  )
}
