import { Link, InertiaLinkProps } from '@inertiajs/react'

export default function NavLink({
  active = false,
  className = '',
  children,
  ...props
}: InertiaLinkProps & { active: boolean }) {
  return (
    <Link
      {...props}
      className={
        'inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none ' +
        (active
          ? 'text-primary focus:border-primary'
          : 'hover:text-primary focus:border-gray-300 dark:focus:border-gray-700') +
        className
      }
    >
      {children}
    </Link>
  )
}
