import * as React from 'react'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import { cn } from '@/lib/utils'
import { usePage } from '@inertiajs/react'

interface NavDataItem {
  title: string
  href: string
  description: string
}

interface NavData {
  personalComponents: NavDataItem[]
  businessComponents: NavDataItem[]
  otherLinks: { href: string; label: string }[]
}

export default function Desktop({ navData }: { navData: NavData }) {
  const { component } = usePage()
  const [activePersonalItem, setActivePersonalItem] = React.useState(navData.personalComponents[0])

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="hover:bg-secondary hover:text-primary data-[state=open]:bg-secondary data-[state=open]:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 supports-[backdrop-filter]:data-[active]:bg-white/30 supports-[backdrop-filter]:data-[state=open]:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:data-[state=open]:bg-accent/50 dark:data-[active]:text-primary dark:data-[state=open]:text-primary">
            Personal
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md dark:from-gray-900 dark:to-gray-950"
                    href={activePersonalItem.href}
                  >
                    <div className="mb-2 mt-4 text-lg font-medium text-primary">
                      {activePersonalItem.title}
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground dark:text-gray-400">
                      {activePersonalItem.description}
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              {navData.personalComponents.map((data) => (
                <ListItem
                  key={data.title}
                  title={data.title}
                  href={data.href}
                  onMouseEnter={() => setActivePersonalItem(data)}
                >
                  {data.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="hover:bg-secondary hover:text-primary data-[state=open]:bg-secondary data-[state=open]:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 supports-[backdrop-filter]:data-[active]:bg-white/30 supports-[backdrop-filter]:data-[state=open]:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:data-[state=open]:bg-accent/50 dark:data-[active]:text-primary dark:data-[state=open]:text-primary">
            Business
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
              {navData.businessComponents.map((data) => (
                <ListItem key={data.title} title={data.title} href={data.href}>
                  {data.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        {navData.otherLinks.map((link) => (
          <NavigationMenuItem key={link.href}>
            <NavigationMenuLink
              href={link.href}
              className={
                navigationMenuTriggerStyle() +
                cn(
                  'hover:bg-secondary hover:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:hover:text-primary',
                  component === link.label.toLowerCase() ? 'text-primary' : '',
                  link.href === '/top-up' ? 'md:max-lg:hidden lg:flex' : ''
                )
              }
            >
              {link.label}
            </NavigationMenuLink>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'> & { onMouseEnter?: () => void }
>(({ className, title, children, onMouseEnter, ...props }, ref) => {
  return (
    <li onMouseEnter={onMouseEnter}>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            'group block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-all hover:bg-secondary hover:text-secondary-foreground focus:bg-secondary focus:text-secondary-foreground dark:hover:bg-accent/50 dark:hover:text-white',
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none group-hover:text-primary">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground dark:text-gray-400">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = 'ListItem'
