'use client'

import { useState, useEffect, useCallback, useRef } from 'react'
import { motion, AnimatePresence, useInView } from 'framer-motion'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import * as React from 'react'
import { cn } from '@/lib/utils'

type AccordionItemType = {
  title: string
  content?: string
  image: string
}

type IndicatorPosition = 'left' | 'right' | 'top' | 'bottom'
type AutoPlayMode = 'off' | 'once' | 'continuous'

interface AccordionSliderProps {
  items: AccordionItemType[]
  itemDuration?: number
  imageTransitionDuration?: number
  indicatorPosition?: IndicatorPosition
  hasContent?: boolean
  useDefaultDesign?: boolean
  showIndicator?: boolean
  containerClassName?: string
  triggerClassName?: string
  contentClassName?: string
  indicatorClassName?: string
  listPosition?: 'left' | 'right'
  autoPlayMode?: AutoPlayMode
}

const AccordionContentWrapper: React.FC<{
  children: React.ReactNode
  isActive: boolean
  className?: string
}> = ({ children, isActive, className }) => (
  <AccordionContent className={className}>
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 0.3,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          <div className="py-2">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  </AccordionContent>
)

const Indicator: React.FC<{
  progress: number
  position: IndicatorPosition
  className?: string
}> = ({ progress, position, className }) => {
  const positionStyles: Record<IndicatorPosition, React.CSSProperties> = {
    left: { left: 0, top: 0, width: '4px', height: '100%' },
    right: { right: 0, top: 0, width: '4px', height: '100%' },
    top: { left: 0, top: 0, width: '100%', height: '4px' },
    bottom: { left: 0, bottom: 0, width: '100%', height: '4px' },
  }

  return (
    <div
      className={cn('absolute overflow-hidden rounded-full bg-gray-200', className)}
      style={positionStyles[position]}
    >
      <motion.div
        className="rounded-full bg-black"
        style={{
          [position === 'left' || position === 'right' ? 'height' : 'width']: `${progress}%`,
          [position === 'left' || position === 'right' ? 'width' : 'height']: '100%',
        }}
        transition={{ duration: 0 }}
      />
    </div>
  )
}

export default function AccordionSlider({
  items,
  itemDuration = 3000,
  imageTransitionDuration = 300,
  indicatorPosition = 'left',
  hasContent = true,
  useDefaultDesign = false,
  showIndicator = true,
  containerClassName = '',
  triggerClassName = '',
  contentClassName = '',
  indicatorClassName = '',
  listPosition = 'left',
  autoPlayMode = 'off',
}: AccordionSliderProps) {
  const [activeItem, setActiveItem] = useState(0)
  const [progress, setProgress] = useState(0)
  const [isAutoPlayPaused, setIsAutoPlayPaused] = useState(false)
  const [hasAutoPlayedOnce, setHasAutoPlayedOnce] = useState(false)
  const animationRef = useRef<number | null>(null)
  const accordionRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const isInView = useInView(containerRef, { once: false, amount: 0.3 })

  const moveToNextItem = useCallback(() => {
    setActiveItem((prevItem) => {
      const nextItem = (prevItem + 1) % items.length
      if (nextItem === 0 && autoPlayMode === 'once') {
        setHasAutoPlayedOnce(true)
      }
      return nextItem
    })
    setProgress(0)
  }, [items.length, autoPlayMode])

  useEffect(() => {
    if (
      !isInView ||
      isAutoPlayPaused ||
      autoPlayMode === 'off' ||
      (autoPlayMode === 'once' && hasAutoPlayedOnce)
    ) {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
      return
    }

    let startTime: number

    const updateProgress = (timestamp: number) => {
      if (!startTime) {
        startTime = timestamp
      }
      const elapsedTime = timestamp - startTime
      const newProgress = Math.min((elapsedTime / itemDuration) * 100, 100)

      setProgress(newProgress)

      if (newProgress >= 100) {
        moveToNextItem()
        startTime = timestamp
      } else {
        animationRef.current = requestAnimationFrame(updateProgress)
      }
    }

    animationRef.current = requestAnimationFrame(updateProgress)

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [
    moveToNextItem,
    itemDuration,
    activeItem,
    isAutoPlayPaused,
    isInView,
    autoPlayMode,
    hasAutoPlayedOnce,
  ])

  useEffect(() => {
    const accordion = accordionRef.current
    if (!accordion) return

    const handleInteraction = () => setIsAutoPlayPaused(true)
    const handleEndInteraction = () => setIsAutoPlayPaused(false)

    accordion.addEventListener('mouseenter', handleInteraction)
    accordion.addEventListener('mouseleave', handleEndInteraction)
    accordion.addEventListener('touchstart', handleInteraction)
    accordion.addEventListener('touchend', handleEndInteraction)

    return () => {
      accordion.removeEventListener('mouseenter', handleInteraction)
      accordion.removeEventListener('mouseleave', handleEndInteraction)
      accordion.removeEventListener('touchstart', handleInteraction)
      accordion.removeEventListener('touchend', handleEndInteraction)
    }
  }, [])

  const getItemClassName = (index: number) => {
    if (useDefaultDesign) return ''
    return cn(
      'border-none rounded-lg transition-all duration-300 ease-in-out overflow-hidden relative',
      index === activeItem ? 'bg-background shadow-lg scale-105' : 'bg-muted hover:bg-muted/80'
    )
  }

  const accordionContent = (
    <div ref={accordionRef} className="flex w-full flex-col p-4 md:w-1/2">
      <Accordion
        type="single"
        value={`${activeItem + 1}`}
        onValueChange={(value) => {
          const newIndex = parseInt(value) - 1
          if (newIndex !== activeItem) {
            setActiveItem(newIndex)
            setProgress(0)
          }
        }}
        className="flex-grow space-y-4"
      >
        {items.map((item, index) => (
          <AccordionItem
            key={index}
            value={`${index + 1}`}
            className={cn(getItemClassName(index), 'relative')}
          >
            <AccordionTrigger
              className={cn(
                'px-4 py-2',
                useDefaultDesign
                  ? ''
                  : 'no-underline [&[data-state=open]>svg]:rotate-180 [&[data-state=open]]:text-black',
                triggerClassName
              )}
            >
              <div className="flex w-full items-center">
                <span className="flex-grow text-left">{item.title}</span>
              </div>
            </AccordionTrigger>
            {hasContent && item.content && (
              <AccordionContentWrapper isActive={index === activeItem} className={contentClassName}>
                <div className="px-4 pb-2">{item.content}</div>
              </AccordionContentWrapper>
            )}
            {showIndicator && (
              <Indicator
                progress={index === activeItem ? progress : 0}
                position={indicatorPosition}
                className={indicatorClassName}
              />
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )

  const imageContent = (
    <div className="flex w-full items-center justify-center p-4 md:w-1/2">
      <div className="relative aspect-[4/3] w-full">
        <AnimatePresence mode="wait">
          <motion.img
            key={activeItem}
            src={items[activeItem].image}
            alt={`Preview for ${items[activeItem].title}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: imageTransitionDuration / 1000, ease: 'easeInOut' }}
            className="h-full w-full rounded-lg object-cover object-center"
          />
        </AnimatePresence>
      </div>
    </div>
  )

  return (
    <motion.div
      ref={containerRef}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      className={cn(
        'mx-auto flex w-full max-w-4xl flex-col bg-background md:flex-row',
        containerClassName
      )}
    >
      {listPosition === 'left' ? (
        <>
          {accordionContent}
          {imageContent}
        </>
      ) : (
        <>
          {imageContent}
          {accordionContent}
        </>
      )}
    </motion.div>
  )
}
