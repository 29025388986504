import { useCallback, useEffect, useRef, useState, memo } from 'react'
import { Search, X } from 'lucide-react'
import { Button } from '@/components/ui/button'
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
  DrawerClose,
  DrawerTitle,
  DrawerDescription,
} from '@/components/ui/drawer'
import { Input } from '@/components/ui/input'

type SearchResult = {
  icon: string
  title: string
  description: string
}

const mockSearchResults: SearchResult[] = [
  { icon: '🌐', title: 'Web Hosting', description: 'Reliable web hosting services' },
  { icon: '📱', title: 'Mobile Plans', description: 'Flexible mobile plans for everyone' },
  { icon: '🏢', title: 'Business Solutions', description: 'Tailored solutions for businesses' },
  { icon: '📺', title: 'TV Packages', description: 'Premium TV channels and content' },
  { icon: '🌟', title: 'VIP Services', description: 'Exclusive services for VIP customers' },
]

export default function SearchDrawer() {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
      e.preventDefault()
      e.stopPropagation()
      setIsSearchOpen((prev) => !prev)
    }
  }, [])

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query)
    if (query.trim() === '') {
      setSearchResults([])
    } else {
      const filteredResults = mockSearchResults.filter(
        (result) =>
          result.title.toLowerCase().includes(query.toLowerCase()) ||
          result.description.toLowerCase().includes(query.toLowerCase())
      )
      setSearchResults(filteredResults)
    }
  }, [])

  const handleClose = useCallback(() => {
    setIsSearchOpen(false)
    setSearchQuery('')
    setSearchResults([])
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  const SearchContent = memo(() => {
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, [])

    return (
      <div
        className="mx-auto flex h-full max-w-4xl flex-col items-center p-4 transition-colors duration-150 hover:bg-secondary hover:text-primary focus-visible:ring-0 data-[state=open]:bg-secondary data-[state=open]:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 supports-[backdrop-filter]:data-[active]:bg-white/30 supports-[backdrop-filter]:data-[state=open]:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:data-[state=open]:bg-accent/50 dark:data-[active]:text-primary dark:data-[state=open]:text-primary"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mb-4 flex w-full items-center justify-between">
          <DrawerTitle className="text-lg font-semibold">Search</DrawerTitle>
          <DrawerClose asChild>
            <Button
              variant="ghost"
              size="icon"
              className="group rounded-full"
              onClick={handleClose}
            >
              <X className="h-5 w-5 transition-all group-hover:rotate-180 group-hover:bg-accent/50 group-hover:text-primary" />
              <span className="sr-only">Close</span>
            </Button>
          </DrawerClose>
        </div>
        <DrawerDescription className="sr-only">
          Search for products and services. Use the input field to enter your search query.
        </DrawerDescription>
        <div className="relative mb-4 w-full">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-muted-foreground" />
          <Input
            ref={inputRef}
            type="search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full pl-10 pr-4 md:w-[400px] lg:w-[600px] xl:w-[800px]"
            aria-label="Search input"
          />
        </div>
        <div className="w-full max-w-[800px] flex-grow overflow-auto">
          {searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div
                key={index}
                className="mb-4 flex items-start space-x-4 rounded-lg p-2 hover:bg-accent"
              >
                <div className="text-2xl" aria-hidden="true">
                  {result.icon}
                </div>
                <div>
                  <h3 className="font-semibold">{result.title}</h3>
                  <p className="text-sm text-muted-foreground">{result.description}</p>
                </div>
              </div>
            ))
          ) : searchQuery ? (
            <p className="text-center text-muted-foreground">No results found</p>
          ) : (
            <div className="space-y-4">
              <h3 className="font-semibold">Popular Searches</h3>
              {mockSearchResults.slice(0, 3).map((result, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-4 rounded-lg p-2 hover:bg-accent"
                >
                  <div className="text-2xl" aria-hidden="true">
                    {result.icon}
                  </div>
                  <div>
                    <h4 className="font-semibold">{result.title}</h4>
                    <p className="text-sm text-muted-foreground">{result.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  })

  return (
    <Drawer open={isSearchOpen} onOpenChange={setIsSearchOpen} shouldScaleBackground={false}>
      <DrawerTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="flex w-fit items-center justify-between rounded-lg border border-border py-1.5 pl-2 pr-3 text-muted-foreground transition-colors duration-150 hover:bg-secondary hover:text-primary focus-visible:ring-0 data-[state=open]:bg-secondary data-[state=open]:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:drop-shadow-sm supports-[backdrop-filter]:hover:bg-white/30 supports-[backdrop-filter]:data-[active]:bg-white/30 supports-[backdrop-filter]:data-[state=open]:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:data-[state=open]:bg-accent/50 dark:data-[active]:text-primary dark:data-[state=open]:text-primary"
          onClick={() => setIsSearchOpen(true)}
        >
          <Search className="mr-3 size-4 flex-none" />
          <span className="inline-flex text-sm lg:max-xl:hidden xl:inline-flex">
            Quick search ...
          </span>
          <span className="ml-auto flex-none pl-3 text-xs font-semibold">⌘K</span>
          <span className="sr-only">Open search</span>
        </Button>
      </DrawerTrigger>
      <DrawerContent className="h-[90%]">
        <SearchContent />
      </DrawerContent>
    </Drawer>
  )
}
