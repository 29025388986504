'use client'

import { Icon, IconName } from '@/components/icons/index'
import { cn } from '@/lib/utils'
import { motion } from 'framer-motion'
import { LazySvg } from '@/components/custom/lazy-svg'
type PartialEasyAccessList = Partial<Record<IconName, string | []>>

const easyAccessList: PartialEasyAccessList = {
  alpha: 'Data Bundles',
  tariffs: 'Tarrifs',
  wifi: 'WiFi',
  'voice-alt': 'Voice Bundles',
  offer: 'My Offers',
  vas: 'VAS',
}

export default function EasyAccess() {
  return (
    <div className="grid grid-cols-2 place-content-stretch gap-3 sm:grid-cols-4 sm:gap-6 md:grid-cols-6">
      {(Object.entries(easyAccessList) as [IconName, string][]).map(([key, value], index) => (
        <motion.div
          initial={{
            opacity: 0,
            // x: index % 2 === 0 ? '-50%' : '50%',
            y: index % 2 ? '-50%' : '50%',
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            y: 0,
          }}
          transition={{
            duration: 0.5,
            delay: index * 0.1,
            ease: 'easeOut',
          }}
          viewport={{ once: true }}
          key={key}
          role="button"
          className={cn(
            'group flex cursor-pointer items-center space-x-1 rounded-xl border border-border p-3 text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white sm:h-28 sm:flex-col sm:justify-around sm:space-x-0 sm:rounded-3xl md:h-24 lg:h-28 lg:w-32 lg:p-4 xl:h-36 xl:w-40 xl:p-6',
            Object.keys(easyAccessList).length === index + 2 || index === 1
              ? 'sm:col-span-2 md:col-span-1'
              : 'col-span-1'
          )}
        >
          <LazySvg
            name={key}
            className="size-4 text-primary transition-colors group-hover:text-white dark:text-foreground sm:size-6 lg:size-8 xl:size-10"
          />
          <div className="text-xs font-semibold sm:text-center md:font-medium lg:font-semibold xl:text-sm">
            {value}
          </div>
        </motion.div>
      ))}
    </div>
  )
}
