import { usePage } from '@inertiajs/react'
import { Link } from '@inertiajs/react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Sheet, SheetContent, SheetTitle } from '@/components/ui/sheet'
import LanguageSwitch from './LanguageSwitch'
import ThemeSwitch from './ThemeSwitch'
import SearchDrawer from './SearchDrawer'
import { cn } from '@/lib/utils'

interface NavDataItem {
  title: string
  href: string
  description: string
}

interface NavData {
  personalComponents: NavDataItem[]
  businessComponents: NavDataItem[]
  otherLinks: { href: string; label: string }[]
}

interface MobileNavProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  navData: NavData
}

export default function Mobile({ isOpen, setIsOpen, navData }: MobileNavProps) {
  const { component } = usePage()

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side="left" className="w-[300px] p-0 sm:w-[400px]">
        <SheetTitle className="sr-only">Mobile Navigation Menu</SheetTitle>
        <nav className="flex h-full flex-col bg-background dark:bg-gray-950">
          <div className="flex items-center justify-start p-4">
            <Link href="/" className="flex items-center" onClick={() => setIsOpen(false)}>
              <span className="text-xl font-bold text-primary">Roshan</span>
            </Link>
          </div>
          <div className="flex-grow space-y-4 overflow-y-auto p-4">
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="personal" className="border-none">
                <AccordionTrigger className="justify-start py-2 text-sm font-medium hover:text-primary hover:no-underline">
                  Personal
                </AccordionTrigger>
                <AccordionContent>
                  <div className="ml-4 space-y-2">
                    {navData.personalComponents.map((component) => (
                      <Link
                        key={component.title}
                        href={component.href}
                        onClick={() => setIsOpen(false)}
                        className="block py-2 text-sm hover:text-primary dark:text-gray-200 dark:hover:bg-gray-900 dark:hover:text-primary"
                      >
                        {component.title}
                      </Link>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="business" className="border-none">
                <AccordionTrigger className="justify-start py-2 text-sm font-medium hover:text-primary hover:no-underline">
                  Business
                </AccordionTrigger>
                <AccordionContent>
                  <div className="ml-4 space-y-2">
                    {navData.businessComponents.map((component) => (
                      <Link
                        key={component.title}
                        href={component.href}
                        onClick={() => setIsOpen(false)}
                        className="block py-2 text-sm hover:text-primary dark:text-gray-200 dark:hover:bg-gray-900 dark:hover:text-primary"
                      >
                        {component.title}
                      </Link>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <div className="space-y-2">
              {navData.otherLinks.map((link) => (
                <Link
                  key={link.href}
                  href={link.href}
                  onClick={() => setIsOpen(false)}
                  className={cn(
                    'block py-2 text-sm font-medium hover:text-primary dark:text-gray-200 dark:hover:bg-gray-900 dark:hover:text-primary',
                    component === link.label.toLowerCase() ? 'text-primary' : ''
                  )}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between bg-secondary p-4">
            <LanguageSwitch />
            <ThemeSwitch />
          </div>
        </nav>
      </SheetContent>
    </Sheet>
  )
}
