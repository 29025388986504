'use client'

import { useState, useEffect } from 'react'
import { useTheme } from '@/hooks/use-theme'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Icon } from '@/components/icons/index'

export default function ThemeSwitch(): JSX.Element {
  const { theme, setTheme } = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const themeOptions = [
    { value: 'light', label: 'Light', icon: 'sun' },
    { value: 'dark', label: 'Dark', icon: 'moon' },
    { value: 'system', label: 'System', icon: 'monitor' },
  ] as const

  const currentTheme = themeOptions.find((option) => option.value === theme) || themeOptions[2]
  useEffect(() => {
    console.log('Theme:', theme)
  }, [theme])
  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={cn(
            'h-9 w-9 rounded-md transition-colors duration-150 hover:bg-secondary hover:text-primary focus-visible:ring-0 data-[state=open]:bg-secondary data-[state=open]:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 supports-[backdrop-filter]:data-[active]:bg-white/30 supports-[backdrop-filter]:data-[state=open]:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:hover:text-primary dark:data-[state=open]:bg-accent/50 dark:data-[active]:text-primary dark:data-[state=open]:text-primary',
            isOpen ? 'bg-secondary text-primary' : ''
          )}
          aria-label={`Current theme: ${currentTheme.label}. Click to change.`}
        >
          <Icon
            name={currentTheme.icon}
            className={cn(
              'size-5',
              currentTheme.value === 'system' ? 'text-input' : 'text-primary'
            )}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-fit">
        {themeOptions.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => {
              setTheme(option.value)
              setIsOpen(false)
            }}
            className="flex cursor-pointer items-center gap-2"
          >
            <Icon name={option.icon} className="size-5" />
            {/* <span className="flex-grow">{option.label}</span>
            {theme === option.value && <Check className="size-4" />} */}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
