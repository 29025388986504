'use client'

import { useState, useEffect } from 'react'

type Theme = 'light' | 'dark' | 'system'

interface UseThemeResult {
  theme: Theme
  setTheme: (theme: Theme) => void
  isDarkMode: boolean
}

export function useTheme(): UseThemeResult {
  const [theme, setTheme] = useState<Theme>(() => {
    if (typeof window !== 'undefined') {
      return (localStorage.getItem('theme') as Theme) || 'system'
    }
    return 'system'
  })

  const [isDarkMode, setIsDarkMode] = useState<boolean>(false)

  const root = window.document.documentElement
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

  const updateTheme = () => {
    if (theme === 'system') {
      setIsDarkMode(mediaQuery.matches)
      root.classList.toggle('dark', mediaQuery.matches)
    } else {
      setIsDarkMode(theme === 'dark')
      root.classList.toggle('dark', theme === 'dark')
    }
  }

  useEffect(() => {
    updateTheme()
    localStorage.setItem('theme', theme)

    const listener = (event: MediaQueryListEvent) => {
      if (theme === 'system') {
        setIsDarkMode(event.matches)
        root.classList.toggle('dark', event.matches)
      }
    }

    mediaQuery.addEventListener('change', listener)

    return () => mediaQuery.removeEventListener('change', listener)
  }, [theme])

  return { theme, setTheme, isDarkMode }
}
