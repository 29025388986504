'use client'

import { motion } from 'framer-motion'
import { SimpleSection } from '@/components/custom/simple-section'
import { Icon, IconName } from '@/components/icons/index'
import { Button } from '@/components/ui/button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { cn } from '@/lib/utils'

type PartialSupportActions = Partial<Record<IconName, string | []>>

const supportActionsList: PartialSupportActions = {
  'credit-loan': 'Credit Loan',
  'data-loan': 'Data Loan',
  'credit-share': 'Credit Share',
  'data-share': 'Data Share',
  'bundle-stack': 'Bundle Share',
}

export default function SupportSection(): JSX.Element {
  return (
    <SimpleSection
      title="Emergency Support"
      subtitle="Roshan provides you with quick access to loans and user-friendly sharing features for your convenience."
    >
      <Drawer shouldScaleBackground={false}>
        <DrawerTrigger asChild>
          <div className="lg:grid-cols-max grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-flow-col lg:grid-cols-5">
            {(Object.entries(supportActionsList) as [IconName, string][]).map(
              ([key, value], index) => (
                <motion.div
                  initial={{
                    opacity: 0,
                    // x: index % 2 === 0 ? '-50%' : '50%',
                    y: index < 2 ? '-50%' : '50%',
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    y: 0,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.1,
                    ease: 'easeOut',
                  }}
                  viewport={{ once: true }}
                  key={key}
                  role="button"
                  className={cn(
                    'group flex h-[140px] cursor-pointer flex-col items-center justify-around rounded-3xl border border-border p-6 text-center text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white',
                    index === Object.keys(supportActionsList).length - 1
                      ? 'col-span-2 lg:col-span-1'
                      : ''
                  )}
                >
                  <Icon
                    name={key}
                    className="size-10 text-primary transition-colors group-hover:text-white dark:text-foreground"
                  />
                  <div className="text-sm font-medium">{value}</div>
                </motion.div>
              )
            )}
          </div>
        </DrawerTrigger>

        <DrawerContent>
          <div className="mx-auto w-full max-w-sm">
            <DrawerHeader>
              <DrawerTitle>Move Goal</DrawerTitle>
              <DrawerDescription>Set your daily activity goal.</DrawerDescription>
            </DrawerHeader>
            <div className="p-4 pb-0"></div>
            <DrawerFooter>
              <Button>Submit</Button>
              <DrawerClose asChild>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </SimpleSection>
  )
}
