'use client'

import { cn } from '@/lib/utils'
import React from 'react'
import WordRotate from '@/components/ui/word-rotate'

interface ImageOnlySectionProps extends React.HTMLAttributes<HTMLDivElement> {
  titles: string[]
  imgUrl: string
}

export function ImageOnlySection({
  titles,
  imgUrl,
  className,
}: ImageOnlySectionProps): React.ReactElement {
  return (
    <section className="relative flex">
      <img
        src={imgUrl}
        alt=""
        className={cn(
          'aspect-[4/2] h-[220px] w-full rounded-2xl object-cover sm:h-[450px] md:rounded-[28px] lg:rounded-[40px]',
          className
        )}
        loading="lazy"
        fetchPriority="high"
      />
      <div className="absolute inset-0 flex items-center justify-start">
        <WordRotate
          words={titles}
          className="flex flex-col pl-8 text-3xl font-bold tracking-[-0.02em] text-white dark:text-primary sm:text-5xl md:pl-20"
        />
      </div>
    </section>
  )
}
