'use client'

import React from 'react'
import { cn } from '@/lib/utils'
import { Card, CardContent } from '@/components/ui/card'

interface MarqueeCardProps {
  className?: string
  reverse?: boolean
  pauseOnHover?: boolean
  children?: React.ReactNode
  vertical?: boolean
  repeat?: number
  [key: string]: any
}

function MarqueeCard({
  className,
  reverse,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 4,
  ...props
}: MarqueeCardProps) {
  return (
    <div
      {...props}
      className={cn(
        'group flex overflow-hidden p-2 [--duration:40s] [--gap:1rem] [gap:var(--gap)]',
        {
          'flex-row': !vertical,
          'flex-col': vertical,
        },
        className
      )}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={cn('flex shrink-0 justify-around [gap:var(--gap)]', {
              'animate-marquee flex-row': !vertical,
              'animate-marquee-vertical flex-col': vertical,
              'group-hover:[animation-play-state:paused]': pauseOnHover,
              '[animation-direction:reverse]': reverse,
            })}
          >
            {children}
          </div>
        ))}
    </div>
  )
}

const reviews = [
  {
    name: 'Jack',
    username: '@jack',
    body: "I've never seen anything like this before. It's amazing. I love it.",
    img: 'https://avatar.vercel.sh/jack',
  },
  {
    name: 'Jill',
    username: '@jill',
    body: "I don't know what to say. I'm speechless. This is amazing.",
    img: 'https://avatar.vercel.sh/jill',
  },
  {
    name: 'John',
    username: '@john',
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: 'https://avatar.vercel.sh/john',
  },
  {
    name: 'Jane',
    username: '@jane',
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: 'https://avatar.vercel.sh/jane',
  },
  {
    name: 'Jenny',
    username: '@jenny',
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: 'https://avatar.vercel.sh/jenny',
  },
  {
    name: 'James',
    username: '@james',
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: 'https://avatar.vercel.sh/james',
  },
]

const firstRow = reviews.slice(0, reviews.length / 2)
const secondRow = reviews.slice(reviews.length / 2)

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string
  name: string
  username: string
  body: string
}) => {
  return (
    <figure
      className={cn(
        'relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4',
        'border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]',
        'dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]'
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">{name}</figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  )
}

export default function CardWithMarqueeBackground() {
  return (
    <section className="relative mx-auto mb-10 mt-16 flex h-[400px] max-w-screen-xl overflow-hidden rounded-[2rem] xl:max-w-[1080px]">
      {/* <div className="flex flex-col items-center justify-center"> */}
      {/* Marquee Background */}
      <div className="absolute inset-0 mx-auto h-[1000px] rotate-[15deg] scale-150 text-center transition-transform">
        <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
          {secondRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
        <MarqueeCard pauseOnHover className="[--duration:40s]">
          {firstRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
        <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
          {secondRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
        <MarqueeCard pauseOnHover className="[--duration:40s]">
          {firstRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
        <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
          {secondRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
        <MarqueeCard pauseOnHover className="[--duration:40s]">
          {firstRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
        <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
          {secondRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </MarqueeCard>
      </div>
      <div className="absolute inset-x-0 bottom-[22px] z-10 mx-auto max-w-screen-sm lg:max-w-3xl">
        {/* Icon */}
        <div className="flex h-[148px] w-full items-center justify-start gap-x-6 rounded-[22px] bg-white/10 p-6 backdrop-blur-md">
          <img src="/images/myroshan-log.png" className="" alt="My Roshan Logo" />
          <div className="flex flex-col items-start justify-center text-muted-foreground">
            <h1 className="text-3xl font-semibold">#MyRoshan</h1>
            <p className="text-[16px] font-medium leading-tight">
              Download My Roshan App now to top-up, subscribe to your favorite internet and call
              plans or customize your own plan, and check your real-time usage.
            </p>
          </div>
          <div className="flex w-[400px] flex-col justify-between gap-2">
            <img
              src="/images/appstore.png"
              alt="My Roshan AppStore Link"
              className="h-11 w-[154px]"
            />
            <img
              src="/images/playstore.png"
              alt="My Roshan PlayStore Link"
              className="h-11 w-[154px]"
            />
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-90% dark:to-black"></div>
    </section>
  )
}
// export default function CardWithMarqueeBackground() {
//   return (
//     <div className="not-prose relative overflow-hidden rounded-xl border bg-background p-0 my-10">
//         <section id="cta">
//             {/* <div className="py-14"> */}
//                 <div className="w-full mx-auto flex flex-col items-center justify-center">
//                     <div className="relative flex w-full max-w-[1000px] flex-col items-center justify-center overflow-hidden rounded-[2rem] p-10 py-14">
//                             <div className="absolute -rotate-[35deg]">
//                                 <MarqueeCard pauseOnHover className="[--duration:40s]">
//                                     {firstRow.map((review) => (
//                                     <ReviewCard key={review.username} {...review} />
//                                     ))}
//                                 </MarqueeCard>
//                                 <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
//                                     {secondRow.map((review) => (
//                                     <ReviewCard key={review.username} {...review} />
//                                     ))}
//                                 </MarqueeCard>
//                                 <MarqueeCard pauseOnHover className="[--duration:40s]">
//                                     {firstRow.map((review) => (
//                                     <ReviewCard key={review.username} {...review} />
//                                     ))}
//                                 </MarqueeCard>
//                                 <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
//                                     {secondRow.map((review) => (
//                                     <ReviewCard key={review.username} {...review} />
//                                     ))}
//                                 </MarqueeCard>
//                                 <MarqueeCard pauseOnHover className="[--duration:40s]">
//                                     {firstRow.map((review) => (
//                                     <ReviewCard key={review.username} {...review} />
//                                     ))}
//                                 </MarqueeCard>
//                                 <MarqueeCard reverse pauseOnHover className="[--duration:40s]">
//                                     {secondRow.map((review) => (
//                                     <ReviewCard key={review.username} {...review} />
//                                     ))}
//                                 </MarqueeCard>
//                             </div>
//                             <div className="z-10 mx-auto size-24 rounded-[2rem] border bg-white/10 p-3 shadow-2xl backdrop-blur-md dark:bg-black/10 lg:size-32"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="mx-auto size-16 text-black dark:text-white lg:size-24"><path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path><path d="M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08v0c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66"></path><path d="m18 15-2-2"></path><path d="m15 18-2-2"></path></svg></div>
//                             <div className="z-10 mt-4 flex flex-col items-center text-center text-black dark:text-white"><h1 className="text-3xl font-bold lg:text-4xl">Stop wasting time on design.</h1><p className="mt-2">Start your 7-day free trial. No credit card required.</p><a href="/" className="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground text-foreground h-11 group mt-4 rounded-[2rem] px-6">Get Started<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1"><path d="m9 18 6-6-6-6"></path></svg></a></div>
//                         <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-70% dark:to-black"></div>
//                     </div>
//                 </div>
//             {/* </div> */}
//         </section>
//     </div>
//   )
// }
