import { Link } from '@inertiajs/react'
import { Search, Menu } from 'lucide-react'
import { Button } from '@/components/ui/button'
import ApplicationLogo from '@/components/ApplicationLogo'
import Desktop from './Desktop'
import Mobile from './Mobile'
import LanguageSwitch, { Locale } from './LanguageSwitch'
import ThemeSwitch from './ThemeSwitch'
import SearchDrawer from './SearchDrawer'
import { usePage } from '@inertiajs/react'
import { PageProps } from '../../types'
import { useEffect, useState } from 'react'
import { motion, useScroll } from 'framer-motion'
import { cn } from '@/lib/utils'

declare module '@inertiajs/core' {
  interface PageProps {
    supportedLocales?: Locale[]
  }
}

export default function MainNavigation() {
  const { supportedLocales } = usePage<PageProps>().props
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const { scrollY } = useScroll()
  const [shouldShowEffect, setShouldShowEffect] = useState(false)

  useEffect(() => {
    const unsubscribe = scrollY.on('change', (latest) => {
      setShouldShowEffect(latest > 0)
    })

    return () => unsubscribe()
  }, [scrollY])

  const personalComponents = [
    {
      title: 'Mobile',
      href: '/personal/mobile',
      description: 'Explore our range of mobile plans and services.',
    },
    {
      title: 'Internet',
      href: '/personal/internet',
      description: 'High-speed internet solutions for your home.',
    },
    {
      title: 'TV',
      href: '/personal/tv',
      description: 'Enjoy a wide variety of TV channels and on-demand content.',
    },
  ]

  const businessComponents = [
    {
      title: 'Solutions',
      href: '/business/solutions',
      description: 'Tailored solutions to meet your business needs.',
    },
    {
      title: 'Enterprise',
      href: '/business/enterprise',
      description: 'Comprehensive services for large-scale enterprises.',
    },
    {
      title: 'SMB',
      href: '/business/smb',
      description: 'Specialized offerings for small and medium businesses.',
    },
  ]

  const navData = {
    personalComponents,
    businessComponents,
    otherLinks: [
      { href: '/tariffs', label: 'Tariffs' },
      { href: '/tez-net', label: 'Tez-Net' },
      { href: '/stores', label: 'Stores' },
      { href: '/m-paisa', label: 'M-Paisa' },
      { href: '/top-up', label: 'Online Top-up' },
    ],
  }

  return (
    <motion.header
      className={cn(
        'lg:16 sticky top-0 z-50 mx-auto px-4 py-4 backdrop-blur transition-all duration-150 md:px-8 xl:px-32',
        shouldShowEffect ? 'border-b border-border bg-background/60 backdrop-blur' : ''
      )}
    >
      <div className="flex max-w-full items-center justify-between">
        <Link href="/" className="flex-shrink-0">
          <ApplicationLogo className="block h-9 w-auto px-2 transition-colors duration-300" />
        </Link>
        <nav className="hidden flex-grow justify-center md:flex">
          <Desktop navData={navData} />
        </nav>
        <div className="flex items-center space-x-4">
          <div className="flex md:hidden lg:flex">
            <SearchDrawer />
          </div>
          <div className="hidden items-center space-x-4 lg:flex">
            <LanguageSwitch locales={supportedLocales} />
            <ThemeSwitch />
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg text-foreground transition-colors hover:text-primary supports-[backdrop-filter]:drop-shadow-sm lg:hidden"
            aria-label="Open menu"
            onClick={() => setIsMobileNavOpen(true)}
          >
            <span className="sr-only">Toggle Menu</span>
            <Menu className="h-5 w-5" />
          </Button>
        </div>
        <Mobile isOpen={isMobileNavOpen} setIsOpen={setIsMobileNavOpen} navData={navData} />
      </div>
    </motion.header>
  )
}
