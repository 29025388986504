'use client'

import React, { useEffect, useState } from 'react'
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import { cn } from '@/lib/utils'

interface WordRotateProps {
  words: string[]
  duration?: number
  framerProps?: HTMLMotionProps<'h1'>
  className?: string
}

export default function WordRotate({
  words,
  duration = 2500,
  framerProps = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
    transition: { duration: 0.2, ease: 'easeOut' },
  },
  className,
}: WordRotateProps): React.ReactElement {
  const [index, setIndex] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length)
    }, duration)

    return () => clearInterval(interval)
  }, [words, duration])

  return (
    <div className="overflow-hidden py-2">
      <AnimatePresence mode="wait">
        <motion.h1
          key={words[index]}
          className={cn(className)}
          {...framerProps}
          transition={{ ...framerProps.transition, staggerChildren: 0.1 }}
        >
          {words[index]}
        </motion.h1>
      </AnimatePresence>
    </div>
  )
}
