'use client'

import React, { useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
} from '@/components/ui/select'
import { useTranslation } from 'react-i18next'
export interface Locale {
  label: string
  value: string
  dir: string
}

interface LanguageSwitchProps {
  locales?: Locale[]
}

const defaultLocales: Locale[] = [
  { label: 'English', value: 'en', dir: 'ltr' },
  { label: 'Dari', value: 'fa', dir: 'rtl' },
  { label: 'Pashto', value: 'ps', dir: 'rtl' },
]

export default function LanguageSwitch({ locales = defaultLocales }: LanguageSwitchProps) {
  const { i18n } = useTranslation()

  useEffect(() => {
    const selectedLocale = locales.find((l) => l.value === i18n.language)
    if (selectedLocale) {
      document.documentElement.dir = selectedLocale.dir
      document.documentElement.lang = selectedLocale.value
    }
  }, [i18n.language, locales])

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  return (
    <Select value={i18n.language} onValueChange={handleLanguageChange}>
      <SelectTrigger className="w-[90px] rounded-lg border-border text-foreground focus-visible:ring-0 supports-[backdrop-filter]:drop-shadow-sm">
        <SelectValue placeholder="Select Language" />
      </SelectTrigger>
      <SelectContent className="border-border">
        <SelectGroup>
          {locales.map((lang) => (
            <SelectItem key={lang.value} value={lang.value}>
              {lang.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
