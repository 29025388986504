import { SVGProps } from 'react'

import Alpha from '@/components/icons/alpha.svg?react'
import ArrowRight from '@/components/icons/arrow-right.svg?react'
import BatteryCharging from '@/components/icons/battery-charging.svg?react'
import BundleStack from '@/components/icons/bundle-stack.svg?react'
import CheckCircle from '@/components/icons/check-circle.svg?react'
import CheckCircleSlim from '@/components/icons/check-circle-slim.svg?react'
import Clock from '@/components/icons/clock.svg?react'
import CreditLoan from '@/components/icons/credit-loan.svg?react'
import CreditShare from '@/components/icons/credit-share.svg?react'
import Currency from '@/components/icons/currency.svg?react'
import CurrencyAlt from '@/components/icons/currency-alt.svg?react'
import Data from '@/components/icons/data.svg?react'
import DataLoan from '@/components/icons/data-loan.svg?react'
import DataShare from '@/components/icons/data-share.svg?react'
import Hash from '@/components/icons/hash.svg?react'
import Lock from '@/components/icons/lock.svg?react'
import Monitor from '@/components/icons/monitor.svg?react'
import Moon from '@/components/icons/moon.svg?react'
import Offer from '@/components/icons/offer.svg?react'
import SettingSwitch from '@/components/icons/setting-switch.svg?react'
import Search from '@/components/icons/search.svg?react'
import SMS from '@/components/icons/sms.svg?react'
import Sun from '@/components/icons/sun.svg?react'
import Tariffs from '@/components/icons/tariffs.svg?react'
import Vas from '@/components/icons/vas.svg?react'
import Voice from '@/components/icons/voice.svg?react'
import VoiceAlt from '@/components/icons/voice-alt.svg?react'
import Whatsapp from '@/components/icons/whatsapp.svg?react'
import Wifi from '@/components/icons/wifi.svg?react'
import XCircle from '@/components/icons/x-circle.svg?react'

export const Icons = {
  'arrow-right': ArrowRight,
  alpha: Alpha,
  'battery-charging': BatteryCharging,
  'bundle-stack': BundleStack,
  'check-circle': CheckCircle,
  'check-circle-slim': CheckCircleSlim,
  clock: Clock,
  'credit-loan': CreditLoan,
  'credit-share': CreditShare,
  currency: Currency,
  'currency-alt': CurrencyAlt,
  data: Data,
  'data-loan': DataLoan,
  'data-share': DataShare,
  hash: Hash,
  lock: Lock,
  monitor: Monitor,
  moon: Moon,
  offer: Offer,
  'setting-switch': SettingSwitch,
  search: Search,
  sms: SMS,
  sun: Sun,
  tariffs: Tariffs,
  vas: Vas,
  voice: Voice,
  'voice-alt': VoiceAlt,
  whatsapp: Whatsapp,
  wifi: Wifi,
  'x-circle': XCircle,
}

// Create a type for the icon names
export type IconName = keyof typeof Icons

// Create a reusable Icon component
export const Icon = ({ name, ...props }: { name: IconName } & SVGProps<SVGSVGElement>) => {
  const IconComponent = Icons[name]
  return <IconComponent {...props} />
}
