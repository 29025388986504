import { PropsWithChildren } from 'react'
import { MagicCard } from '@/components/magicui/magic-card'
import { useTheme } from '@/hooks/use-theme'
import Alpha from '@/components/icons/alpha.svg?react'
import ExpandingCardsExample from '@/components/experiments/exp-accordion-card'
import AccordionSlider from '@/components/experiments/accordian-slider'
import CardWithMarqueeBackground from '@/components/experiments/marquee-demo'
const IMAGES = ['/images/home-ph.jpeg', '/images/home-2-ph.png', '/images/home-ph.jpeg']
import { useTranslation } from 'react-i18next'
const demoItems = [
  {
    title: 'Mountain Landscape',
    content: 'A serene view of mountains with a lake in the foreground.',
    image: '/images/e-sim.png',
  },
  {
    title: 'City Skyline',
    content: 'A dramatic city skyline at sunset with skyscrapers silhouetted against the sky.',
    image: '/images/home-ph.jpeg',
  },
  {
    title: 'Tropical Beach',
    content: 'A pristine tropical beach with crystal clear water and palm trees.',
    image: '/images/home-2-ph.png',
  },
]

export default function stores({ children }: PropsWithChildren) {
  const { isDarkMode } = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <ExpandingCardsExample />

      <div className="flex flex-col p-10">
        <span>{t('welcome')}</span>
        <span>{t('goodbye')}</span>
      </div>
      <div className="space-y-32 bg-gray-100 p-8">
        <h1 className="mb-8 text-center text-4xl font-bold">AccordionSlider Demos</h1>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Default Configuration (No Auto-play)</h2>
          <p className="mb-4 text-gray-600">Auto-play is off by default</p>
          <AccordionSlider items={demoItems} />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Continuous Auto-play</h2>
          <p className="mb-4 text-gray-600">Auto-play continues indefinitely</p>
          <AccordionSlider items={demoItems} autoPlayMode="continuous" />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Auto-play Once</h2>
          <p className="mb-4 text-gray-600">Auto-play stops after one full cycle</p>
          <AccordionSlider items={demoItems} autoPlayMode="once" />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Small Images (Responsive Fit)</h2>
          <p className="mb-4 text-gray-600">Demonstrating responsive fit with small images</p>
          <AccordionSlider items={demoItems} />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Large Images (Responsive Fit)</h2>
          <p className="mb-4 text-gray-600">Demonstrating responsive fit with large images</p>
          <AccordionSlider items={demoItems} />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Default Design with Indicator</h2>
          <p className="mb-4 text-gray-600">
            Using default accordion design with progress indicator
          </p>
          <AccordionSlider
            items={demoItems}
            useDefaultDesign={true}
            showIndicator={true}
            autoPlayMode="continuous"
          />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Custom Design without Indicator</h2>
          <p className="mb-4 text-gray-600">Custom design without progress indicator</p>
          <AccordionSlider items={demoItems} showIndicator={false} autoPlayMode="continuous" />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Right Indicator, No Content</h2>
          <p className="mb-4 text-gray-600">Progress indicator on the right, without content</p>
          <AccordionSlider
            items={demoItems}
            indicatorPosition="right"
            hasContent={false}
            autoPlayMode="continuous"
          />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Top Indicator, Slower Item Duration</h2>
          <p className="mb-4 text-gray-600">Progress indicator on top, with slower item duration</p>
          <AccordionSlider
            items={demoItems}
            indicatorPosition="top"
            itemDuration={5000}
            autoPlayMode="continuous"
          />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">List on Right Side</h2>
          <p className="mb-4 text-gray-600">Accordion list positioned on the right side</p>
          <AccordionSlider items={demoItems} listPosition="right" autoPlayMode="continuous" />
        </section>

        <section className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-2xl font-semibold">Custom Classes</h2>
          <p className="mb-4 text-gray-600">Using custom classes for styling</p>
          <AccordionSlider
            items={demoItems}
            containerClassName="bg-gray-100 p-4 rounded-xl"
            triggerClassName="font-bold text-blue-600"
            contentClassName="italic text-gray-600"
            indicatorClassName="bg-blue-200"
            autoPlayMode="continuous"
          />
        </section>
      </div>
    </>
  )
}
