import { ComponentProps, FC, useEffect, useRef, useState } from 'react'

interface LazySvgProps extends ComponentProps<'svg'> {
  name: string
  className?: string
}

// https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline/61472427#61472427
const useLazySvgImport = (name: string) => {
  const importRef = useRef<FC<ComponentProps<'svg'>>>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        importRef.current = // update the link to import from public folder
          // await import(`../../../../public/storage/navigation-icons/${name}.svg?react`)
          (await import(`../icons/${name}.svg?react`)).default // We use `?react` here following `vite-plugin-svgr`'s convention.
      } catch (err) {
        setError(err as Error)
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [name])

  return {
    error,
    loading,
    Svg: importRef.current,
  }
}

export const LazySvg = ({ name, className, ...props }: LazySvgProps) => {
  const { loading, error, Svg } = useLazySvgImport(name)

  if (error) {
    return 'An error occurred'
  }

  if (loading) {
    return 'Loading...'
  }

  if (!Svg) {
    return null
  }

  return <Svg stroke={'currentColor'} {...props} className={className} />
}
